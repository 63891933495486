import request from '../utils';
import chartOptions from '../chart/chart_options';
import common from '../component/common.js';
import store from '../../store';

const getInverterLiveData = (formdata, measureList) => new Promise((resolve) => {
  const url = '/panel/inverter/list';
  const tempData = request.Api(url, formdata, 'post');

  tempData.then((_rawData) => {

    const sortType = 'datetimestamp';

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });
 
    const option = {};
    const tempDataType = [];
    let index = 0;

    measureList.forEach((measure) => {
      const data = [];
      const seriesList = [];

      Object.keys(res).forEach((key) => {
        if (index === 0) {
          tempDataType.push(res[key].date);
        }

        if (measure.value === 'Efficiency') {
          if (res[key][measure.value] < 120) {
            data.push(res[key][measure.value]);
          }
        } else {
          data.push(res[key][measure.value]);
        }
      });
      index += 1;

      seriesList.push({
        name: measure.text,
        type: 'line',
        stack: 'x',
        data,
      });

      const colors = ['#5470C6', '#91CC75', '#EE6666'];

      option[measure.value] = chartOptions.line_options(tempDataType, measure,
        seriesList, colors);
    });

    resolve(option);
  });
});

const getSantralComparisonMinuteChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel, inverterList, stepTime) => new Promise((resolve) => {
  const temp_Data = request.Api(formdata.url, formdata.params, 'post');
  temp_Data.then((_rawData) => {
    const sortType = common.setSortType(reportTypeModel);

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let mm;
      const {
        INVERTER
      } = item;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;

      if (!tempTimeData[INVERTER]) {
        tempTimeData[INVERTER] = {};
      }

      tempTimeData[INVERTER][date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};

    const {
      measures
    } = formdata.params.condiniton;
    const {
      measureList
    } = store.getters;
    let selectMeasure;

    measureList.INVERTER.forEach((item) => {
      if (item.text == measures) {
        selectMeasure = {
          text: item.text,
          value: item.text,
          unit: item.unit,
        };
      }
    });

    let index = 0;

    inverterList.forEach((str) => {
      const total = 0;
      const minute = 0;
      const hour = 5;
      const tempData = [];

      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += stepTime) {
          let h;
          let
            m;
          i < 10 ? h = `0${i}` : h = `${i}`;
          x < 10 ? m = `0${x}` : m = `${x}`;
          const time = `${h}:${m}`;
          const obj = {};
          obj.date = time;

          if (dateList.indexOf(time) < 0) {
            dateList.push(time);
          }

          let tempVal = 0;

          if (tempTimeData.hasOwnProperty(str.value)) {
            if (tempTimeData[str.value].hasOwnProperty(time)) {
              const tempValue = tempTimeData[str.value][time];
              tempVal = common.setMeasures(selectMeasure, tempValue);
            }
          }

          tempData.push(tempVal);

          obj[str.value] = tempVal;
          dessert[time] = {
            ...dessert[time],
            ...obj,
          };
        }
      }

      const obj = {
        name: `${str.text} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
        show: false,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);
      series.push(obj);
      index++;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, inverterList, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

const getSantralComparisonHourlyChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel, inverterList) => new Promise((resolve) => {
  const temp_Data = request.Api(formdata.url, formdata.params, 'post');
  temp_Data.then((_rawData) => {
    const sortType = common.setSortType(reportTypeModel);

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;

      return 0;
    });

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let mm;
      const {
        INVERTER
      } = item;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;

      const date = `${hh}`;

      if (!tempTimeData[INVERTER]) {
        tempTimeData[INVERTER] = {};
      }

      tempTimeData[INVERTER][date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};

    const {
      measures
    } = formdata.params.condiniton;

    const {
      measureList
    } = store.getters;
    let selectMeasure;

    measureList.INVERTER.forEach((item) => {
      if (item.text == measures) {
        selectMeasure = {
          text: item.text,
          value: item.text,
          unit: item.unit,
        };
      }
    });

    let index = 0;

    inverterList.forEach((str) => {
      let total = 0;
      const minute = 0;
      const hour = 5;
      const tempData = [];

      for (let i = hour; i <= 20; i++) {
        let h;
        let
          m;
        i < 10 ? h = `0${i}` : h = `${i}`;

        const time = h;
        const obj = {};
        obj.date = time;

        if (dateList.indexOf(time) < 0) {
          dateList.push(time);
        }

        let tempVal = 0;

        if (tempTimeData[str.value] !== undefined) {
          const tempValue = tempTimeData[str.value][time];
          if (tempValue !== undefined) {
            tempVal = common.setMeasures(selectMeasure, tempValue, total);

            if (!isNaN(tempValue[selectMeasure.value])) {
              total = tempValue[selectMeasure.value];
            }
          }
        }

        tempData.push(tempVal);

        obj[str.value] = tempVal;
        dessert[time] = {
          ...dessert[time],
          ...obj,
        };
      }

      const obj = {
        name: `${str.text} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);

      series.push(obj);
      index++;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, inverterList, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

export default {
  getInverterLiveData,
  getSantralComparisonMinuteChart,
  getSantralComparisonHourlyChart,
};
