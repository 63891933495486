<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />
                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <span class="pr-3"> <h3>İnverter verileri</h3> </span>
                </v-col>
                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santral"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="inverter"
                    :items="inverterList[santral]"
                    label="İnverter"
                  />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Picker in menu"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3">
                  <v-btn color="primary" @click="getDailyMinuteEnergy">
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-for="measure in measureList"
              ref="renderComponent"
              :key="measure.value"
              cols="12"
            >
              <template v-if="chartData[measure.value]">
                <echart
                  :id="measure.value + '-inverter-value'"
                  class-name="widget-content"
                  height="350px"
                  width="100%"
                  :chart-data="chartData[measure.value]"
                />
                <v-divider class="" />
              </template>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as LineChartOption from "@/api/Inverter/ChartOption";
import moment from "moment";
import Echart from "@/views/components/Echart/Chart.vue";
import i18n from "@/locale";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    inverter: "",
    riseze: "false",
    startDate: moment().format("YYYY-MM-DD"),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format("YYYY"),
    menu: false,
    modal: false,
    menu2: false,
    chartData: {},
    measureList: [],
    inverterList: [],
    santral: "",
    santralInfo: [],
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (Object.keys(santralDataList).length > 0) {
        return santralDataList;
      }
    },
  },
  watch: {
    $route: function (from, to) {
      this.riseze = "true";
    },
    inverter() {
      this.chartData = {};
      const inverter = this.santralInfo[this.santral].INVERTER;
      const tempMeasureList = inverter[this.inverter].general_measure_list;
      const measureList = [];
      const typeList = ["avg", "sum"];
      tempMeasureList.forEach((measure) => {
        if (typeList.indexOf(measure.formuletype) > -1) {
          measureList.push({
            text: i18n.t(measure.text),
            value: measure.value,
            unit: measure.unit,
            type: measure.type,
          });
        }
      });
      this.measureList = measureList;
      this.getDailyMinuteEnergy();
    },

    santral() {
      const rest = this.santralInfo[this.santral].INVERTER;
      const inverterList = [];

      Object.keys(rest).forEach((key) => {
        inverterList.push({
          text: rest[key].INVERTERNAME,
          value: rest[key].INVERTER,
        });
      });

      this.inverterList[this.santral] = inverterList;
      this.inverter = inverterList[0].value;
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const StatsMeasure = self.$store.getters.santralInfo;

      if (santral !== undefined && Object.keys(StatsMeasure).length > 0) {
        self.santral = santral;
        self.santralInfo = StatsMeasure;
        clearInterval(myVar1);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format("YYYY-MM-DD");

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          inverter: this.inverter,
        },
      };

      // const themes = this.$vuetify.theme.themes.light;
      const tempData = LineChartOption.default.getInverterLiveData(
        params,
        this.measureList
      );
      tempData.then((res) => {
        this.chartData = res;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
